
// ========================================================================================
// comment
export const AT_SEND_COMMENT_POST_REQUEST = "AT_SEND_COMMENT_POST_REQUEST";
export const AT_SEND_COMMENT_POST_SUCCESS = "AT_SEND_COMMENT_POST_SUCCESS";
export const AT_SEND_COMMENT_POST_FAILURE = "AT_SEND_COMMENT_POST_FAILURE";

export const AT_DELETE_COMMENT_POST_REQUEST = "AT_DELETE_COMMENT_POST_REQUEST";
export const AT_DELETE_COMMENT_POST_SUCCESS = "AT_DELETE_COMMENT_POST_SUCCESS";
export const AT_DELETE_COMMENT_POST_FAILURE = "AT_DELETE_COMMENT_POST_FAILURE";

// ========================================================================================
// setting
export const AT_CHANGE_SETTING_BANCLICKMOUSE_SUCCESS = "AT_CHANGE_SETTING_BANCLICKMOUSE_SUCCESS";

// ========================================================================================
// contact
export const AT_SEND_MESSAGE_CONTACT_REQUEST = "AT_SEND_MESSAGE_CONTACT_REQUEST";
export const AT_SEND_MESSAGE_CONTACT_SUCCESS = "AT_SEND_MESSAGE_CONTACT_SUCCESS";
export const AT_SEND_MESSAGE_CONTACT_FAILURE = "AT_SEND_MESSAGE_CONTACT_FAILURE";

// ==========================================================================================
// course
export const AT_ADD_COMMENT_VIDEO_BY_USER_ID_REQUEST = "AT_ADD_COMMENT_VIDEO_BY_USER_ID_REQUEST";
export const AT_ADD_COMMENT_VIDEO_BY_USER_ID_SUCCESS = "AT_ADD_COMMENT_VIDEO_BY_USER_ID_SUCCESS";
export const AT_ADD_COMMENT_VIDEO_BY_USER_ID_FAILURE = "AT_ADD_COMMENT_VIDEO_BY_USER_ID_FAILURE";

export const AT_PATCH_LIKE_VIDEO_BY_USER_ID_REQUEST = "AT_PATCH_LIKE_VIDEO_BY_USER_ID_REQUEST";
export const AT_PATCH_LIKE_VIDEO_BY_USER_ID_SUCCESS = "AT_PATCH_LIKE_VIDEO_BY_USER_ID_SUCCESS";
export const AT_PATCH_LIKE_VIDEO_BY_USER_ID_FAILURE = "AT_PATCH_LIKE_VIDEO_BY_USER_ID_FAILURE";

export const AT_DELETE_COMMENT_VIDEO_BY_USER_ID_REQUEST = "AT_DELETE_COMMENT_VIDEO_BY_USER_ID_REQUEST";
export const AT_DELETE_COMMENT_VIDEO_BY_USER_ID_SUCCESS = "AT_DELETE_COMMENT_VIDEO_BY_USER_ID_SUCCESS";
export const AT_DELETE_COMMENT_VIDEO_BY_USER_ID_FAILURE = "AT_DELETE_COMMENT_VIDEO_BY_USER_ID_FAILURE";

// ========================================================================================

// multi language
export const AT_CHANGE_MULTI_LANGUAGE_SUCCESS = "AT_CHANGE_MULTI_LANGUAGE_SUCCESS";

// user - profile
export const AT_CHANGE_PASSWORD_REQUEST = "AT_CHANGE_PASSWORD_REQUEST";
export const AT_CHANGE_PASSWORD_SUCCESS = "AT_CHANGE_PASSWORD_SUCCESS";
export const AT_CHANGE_PASSWORD_FAILURE = "AT_CHANGE_PASSWORD_FAILURE";

export const AT_UPDATE_PROFILE_REQUEST = "AT_UPDATE_PROFILE_REQUEST";
export const AT_UPDATE_PROFILE_SUCCESS = "AT_UPDATE_PROFILE_SUCCESS";
export const AT_UPDATE_PROFILE_FAILURE = "AT_UPDATE_PROFILE_FAILURE";

export const AT_USER_GET_PROFILE_REQUEST = "AT_USER_GET_PROFILE_REQUEST";
export const AT_USER_GET_PROFILE_SUCCESS = "AT_USER_GET_PROFILE_SUCCESS";
export const AT_USER_GET_PROFILE_FAILURE = "AT_USER_GET_PROFILE_FAILURE";
export const AT_USER_SET_PROFILE_NULL = "AT_USER_SET_PROFILE_NULL";

export const AT_UPLOAD_AVATAR_USER_REQUEST = "AT_UPLOAD_AVATAR_USER_REQUEST";
export const AT_UPLOAD_AVATAR_USER_SUCCESS = "AT_UPLOAD_AVATAR_USER_SUCCESS";
export const AT_UPLOAD_AVATAR_USER_FAILURE = "AT_UPLOAD_AVATAR_USER_FAILURE";


// authencation
export const AT_AUTH_RESET_PASSWORD_REQUEST = "AT_AUTH_RESET_PASSWORD_REQUEST";
export const AT_AUTH_RESET_PASSWORD_SUCCESS = "AT_AUTH_RESET_PASSWORD_SUCCESS";
export const AT_AUTH_RESET_PASSWORD_FAILURE = "AT_AUTH_RESET_PASSWORD_FAILURE";

export const AT_AUTH_ACTIVE_ACCOUNT_REQUEST = "AT_AUTH_ACTIVE_ACCOUNT_REQUEST";
export const AT_AUTH_ACTIVE_ACCOUNT_SUCCESS = "AT_AUTH_ACTIVE_ACCOUNT_SUCCESS";
export const AT_AUTH_ACTIVE_ACCOUNT_FAILURE = "AT_AUTH_ACTIVE_ACCOUNT_FAILURE";

export const AT_AUTH_RESEND_LINK_ACTIVATION_REQUEST = "AT_AUTH_RESEND_LINK_ACTIVATION_REQUEST";
export const AT_AUTH_RESEND_LINK_ACTIVATION_SUCCESS = "AT_AUTH_RESEND_LINK_ACTIVATION_SUCCESS";
export const AT_AUTH_RESEND_LINK_ACTIVATION_FAILURE = "AT_AUTH_RESEND_LINK_ACTIVATION_FAILURE";

export const AT_AUTH_FORGOT_PASSWORD_REQUEST = "AT_AUTH_FORGOT_PASSWORD_REQUEST";
export const AT_AUTH_FORGOT_PASSWORD_SUCCESS = "AT_AUTH_FORGOT_PASSWORD_SUCCESS";
export const AT_AUTH_FORGOT_PASSWORD_FAILURE = "AT_AUTH_FORGOT_PASSWORD_FAILURE";

export const AT_AUTH_SIGNUP_REQUEST = "AT_AUTH_SIGNUP_REQUEST";
export const AT_AUTH_SIGNUP_SUCCESS = "AT_AUTH_SIGNUP_SUCCESS";
export const AT_AUTH_SIGNUP_FAILURE = "AT_AUTH_SIGNUP_FAILURE";

export const AT_AUTH_LOGIN_REQUEST = "AT_AUTH_LOGIN_REQUEST";
export const AT_AUTH_LOGIN_SUCCESS = "AT_AUTH_LOGIN_SUCCESS";
export const AT_AUTH_LOGIN_FAILURE = "AT_AUTH_LOGIN_FAILURE";

export const AT_AUTH_LOGIN_GOOGLE_REQUEST = "AT_AUTH_LOGIN_GOOGLE_REQUEST";
export const AT_AUTH_LOGIN_GOOGLE_SUCCESS = "AT_AUTH_LOGIN_GOOGLE_SUCCESS";
export const AT_AUTH_LOGIN_GOOGLE_FAILURE = "AT_AUTH_LOGIN_GOOGLE_FAILURE";

export const AT_AUTH_REDIRECT_TO_LOGIN_PAGE = "AT_AUTH_REDIRECT_TO_LOGIN_PAGE";
export const AT_AUTH_LOGOUT = "AT_AUTH_LOGOUT";

export const AT_LOADING_SHOW = "AT_LOADING_SHOW";
export const AT_LOADING_HIDE = "AT_LOADING_HIDE";

export const AT_HELLO = "AT_HELLO";
