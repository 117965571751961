import { call, put, takeLatest, delay } from "redux-saga/effects";
import i18n from "./../../i18n";
import * as actions from "../Constants/actionTypes";
import { STATUS_CODE } from "./../../Constants/statusCodeApi";
import {
    resetPasswordFailureAction,
    resetPasswordSuccessAction,
    activeAccountFailureAction,
    activeAccountSuccessAction,
    resendActivationLinkFailureAction,
    resendActivationLinkSuccessAction,
    forgotPasswordFailureAction,
    forgotPasswordSuccessAction,
    signupFailureAction,
    signupSuccessAction,
    loginFailureAction,
    loginSuccessAction,
    loginGoogleFailureAction,
    loginGoogleSuccessAction,
} from "./../Actions/authAction";
import { loginApi, signupApi, forgotPasswordApi, resendActivationLinkApi, activeAccountApi,
        resetPasswordApi, loginGoogleApi } from "../../Apis/authApi";  
import { getUserProfileRequestAction } from "./../Actions/userAction";
import { showLoadingAction, hideLoadingAction } from "../Actions/loadingAction";

export default function* authSaga() {    
    yield takeLatest(actions.AT_AUTH_LOGIN_GOOGLE_REQUEST, loginGoogleRequestSaga);
    yield takeLatest(actions.AT_AUTH_LOGIN_REQUEST, loginRequestSaga);
    yield takeLatest(actions.AT_AUTH_SIGNUP_REQUEST, signupRequestSaga);
    yield takeLatest(actions.AT_AUTH_FORGOT_PASSWORD_REQUEST, forgotPasswordRequestSaga);
    yield takeLatest(actions.AT_AUTH_RESEND_LINK_ACTIVATION_REQUEST, resendActivationLinkRequestSaga);  
    yield takeLatest(actions.AT_AUTH_ACTIVE_ACCOUNT_REQUEST, activeAccountRequestSaga);
    yield takeLatest(actions.AT_AUTH_RESET_PASSWORD_REQUEST, resetPasswordRequestSaga);  
}

function* loginGoogleRequestSaga({ payload }) {
    yield put(showLoadingAction());
    // const { email, password } = payload.data;
    // console.log("loginGoogleRequestSaga_payload.data: ", payload.data)
    const resp = yield call(loginGoogleApi, payload.data);
    const { status, data } = resp;
    // console.log("loginGoogleRequestSaga_status: ", status)
    if (status === STATUS_CODE.HTTP_200_OK) {
        yield put(loginGoogleSuccessAction(data));
        yield put(getUserProfileRequestAction());
    } else if (status === STATUS_CODE.HTTP_401_UNAUTHORIZED) {
        const error = i18n.t("Account info is incorrect or not active yet!");
        yield put(loginGoogleFailureAction({ error }));
    } else {
        const error = i18n.t("Can not login at this time. Please try again!");
        yield put(loginGoogleFailureAction({ error }));
    }
    yield delay(500);
    yield put(hideLoadingAction());
}

function* loginRequestSaga({ payload }) {
    yield put(showLoadingAction());
    const { email, password } = payload.data;
    const resp = yield call(loginApi, {
        username: email,
        password,
    });
    const { status, data } = resp;
    if (status === STATUS_CODE.HTTP_200_OK) {
        yield put(loginSuccessAction(data));
        yield put(getUserProfileRequestAction());
    } else if (status === STATUS_CODE.HTTP_401_UNAUTHORIZED) {
        const error = i18n.t("Account info is incorrect or not active yet!");
        yield put(loginFailureAction({ error }));
    } else {
        const error = i18n.t("Can not login at this time. Please try again!");
        yield put(loginFailureAction({ error }));
    }
    yield delay(500);
    yield put(hideLoadingAction());
}

function* signupRequestSaga({ payload }) { 
    yield put(showLoadingAction());
    const resp = yield call(signupApi, {
        ...payload.data
    });
    const { status } = resp; 
    if (status === STATUS_CODE.HTTP_201_CREATED) {
        // const { message } = data;
        const message = i18n.t("Registered successfully! A mail sent to your mailbox for activation account!");
        yield put(signupSuccessAction(message));
    } else if (status === STATUS_CODE.HTTP_ME_450_EMAIL_EXIST) { 
        const error = i18n.t("Email exist!");
        yield put(signupFailureAction({ error }));
    } else {
        const error = i18n.t("Can not signup at this time. Please try again!");
        yield put(signupFailureAction({ error }));
    }
    yield delay(500);
    yield put(hideLoadingAction());
}

function* forgotPasswordRequestSaga({ payload }) { 
    yield put(showLoadingAction());
    const resp = yield call(forgotPasswordApi, {
        ...payload.data,
    });
    const { status } = resp; 
    if (status === STATUS_CODE.HTTP_200_OK) {
        // const { message } = data;
        const message = i18n.t("Send an activation link to your email successfully!");
        yield put(forgotPasswordSuccessAction(message));
    } else if (status === STATUS_CODE.HTTP_ME_451_EMAIL_DOES_NOT_EXIST) { 
        const error = i18n.t("Email does not exist!");
        yield put(forgotPasswordFailureAction({ error }));
    } else if (status === STATUS_CODE.HTTP_ME_452_ACCOUNT_IS_NOT_ACTIVATED) { 
        const error = i18n.t("The account is not activated!");
        yield put(forgotPasswordFailureAction({ error }));
    } else {
        const error = i18n.t("Can not get forgot password at this time. Please try again!");
        yield put(forgotPasswordFailureAction({ error }));
    }
    yield delay(500);
    yield put(hideLoadingAction());
}

function* resendActivationLinkRequestSaga({ payload }) { 
    yield put(showLoadingAction());
    const resp = yield call(resendActivationLinkApi, {
        ...payload.data,
    });
    const { status } = resp; 
    if (status === STATUS_CODE.HTTP_200_OK) {
        // const { message } = data;
        const message = i18n.t("Send an activation link to your email successfully!");
        yield put(resendActivationLinkSuccessAction(message));
    } else if (status === STATUS_CODE.HTTP_ME_451_EMAIL_DOES_NOT_EXIST) { 
        const error = i18n.t("Email does not exist!");
        yield put(resendActivationLinkFailureAction({ error }));
    } else if (status === STATUS_CODE.HTTP_ME_453_ACCOUNT_IS_ACTIVATED) { 
        const error = i18n.t("The account is activated already!");
        yield put(resendActivationLinkFailureAction({ error }));
    } else {
        const error = i18n.t("Can not resend activation link at this time. Please try again!");
        yield put(resendActivationLinkFailureAction({ error }));
    }
    yield delay(500);
    yield put(hideLoadingAction());
}

function* activeAccountRequestSaga({ payload }) { 
    yield put(showLoadingAction());
    const resp = yield call(activeAccountApi, {
        ...payload.data
    });
    const { status } = resp; 
    if (status === STATUS_CODE.HTTP_200_OK) {
        // const { message } = data;
        const message = i18n.t("Activate account successfully!");
        yield put(activeAccountSuccessAction(message));
    } else if (status === STATUS_CODE.HTTP_ME_451_EMAIL_DOES_NOT_EXIST) { 
        const error = i18n.t("Email does not exist!");
        yield put(activeAccountFailureAction({ error }));
    } else if (status === STATUS_CODE.HTTP_ME_453_ACCOUNT_IS_ACTIVATED) { 
        const error = i18n.t("The account is activated already!");
        yield put(activeAccountFailureAction({ error }));
    }else if (status === STATUS_CODE.HTTP_ME_455_TOKEN_INCORRECT_OR_EXPIRED) { 
        const error = i18n.t("Token is incorrect or expired!");
        yield put(activeAccountFailureAction({ error }));
    } else {
        const error = i18n.t("Can not active account at this time. Please try again!");
        yield put(activeAccountFailureAction({ error }));
    }
    yield delay(500);
    yield put(hideLoadingAction());
}

function* resetPasswordRequestSaga({ payload }) { 
    yield put(showLoadingAction());
    const { new_password } = payload.data;
    const resp = yield call(resetPasswordApi, {
        password: new_password,
        ...payload.data,
    });
    const { status } = resp; 
    if (status === STATUS_CODE.HTTP_200_OK) {
        // const { message } = data;
        const message = i18n.t("Reset password successfully!");
        yield put(resetPasswordSuccessAction(message));
    }else if (status === STATUS_CODE.HTTP_ME_455_TOKEN_INCORRECT_OR_EXPIRED) { 
        const error = i18n.t("Token is incorrect or expired or the email does not exist!");
        yield put(resetPasswordFailureAction({ error }));
    } else {
        const error = i18n.t("Can not reset password at this time. Please try again!");
        yield put(resetPasswordFailureAction({ error }));
    }
    yield delay(500);
    yield put(hideLoadingAction());
}