import { lazy } from "react";

import i18n from "../i18n";

const FrontendLayout = lazy(() => import("../Frontend/Layout/FrontendLayout"));
const FrontendLayoutAccount = lazy(() =>
  import("../Frontend/Layout/FrontendLayoutAccount")
);

const HomePage = lazy(() => import("../Frontend/Pages/Home/HomePage"));
const LoginPage = lazy(() =>
  import("../Frontend/Pages/Account/Login/LoginPage")
);
const ActiveAccountPage = lazy(() =>
  import("../Frontend/Pages/Account/ActiveAccount/ActiveAccountPage")
);
const ResendActivationLinkPage = lazy(() =>
  import(
    "../Frontend/Pages/Account/ResendActivationLink/ResendActivationLinkPage"
  )
);
const ForgotPasswordPage = lazy(() =>
  import("../Frontend/Pages/Account/ForgotPassword/ForgotPasswordPage")
);
const ResetPasswordPage = lazy(() =>
  import("../Frontend/Pages/Account/ResetPassword/ResetPasswordPage")
);
const RegisterPage = lazy(() =>
  import("../Frontend/Pages/Account/Register/RegisterPage")
);
const LoginGooglePage = lazy(() =>
  import("../Frontend/Pages/Account/Login/LoginGooglePage")
);

const routers = [
  {
    exact: true,
    path: "/",
    name: i18n.t("Home"),
    component: HomePage,
    layout: FrontendLayout,
  },
  {
    path: "/login",
    name: i18n.t("Login"),
    component: LoginPage,
    layout: FrontendLayoutAccount,
  },
  {
    path: "/dang-nhap-ctv",
    name: i18n.t("Login"),
    component: LoginGooglePage,
    layout: FrontendLayoutAccount,
  },
  {
    path: "/forgot-password",
    name: i18n.t("Forgot password"),
    component: ForgotPasswordPage,
    layout: FrontendLayoutAccount,
  },
  {
    path: "/reset-password",
    name: i18n.t("Reset password"),
    component: ResetPasswordPage,
    layout: FrontendLayoutAccount,
  },
  {
    path: "/register",
    name: i18n.t("Register"),
    component: RegisterPage,
    layout: FrontendLayoutAccount,
  },
  {
    path: "/active-account",
    name: i18n.t("Active account"),
    component: ActiveAccountPage,
    layout: FrontendLayoutAccount,
  },
  {
    path: "/resend-link-active",
    name: i18n.t("Resend active link"),
    component: ResendActivationLinkPage,
    layout: FrontendLayoutAccount,
  },
];

export default routers;
