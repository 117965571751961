import * as actions from "../Constants/actionTypes";

const initState = {
    isLoading: false,
    isBanClickMouse: false,
};

const settingReducer = (state = initState, action) => {
    switch (action.type) {        
        case actions.AT_CHANGE_SETTING_BANCLICKMOUSE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                isBanClickMouse: action.payload,
            };
        }
        default:
            return state;
    }
};

export default settingReducer;
