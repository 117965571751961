import { snackbarService } from "uno-material-ui";

export const AlertSuccess = message => {
    return snackbarService.showSnackbar(message, "success");
};

export const AlertError = message => {
    return snackbarService.showSnackbar(message, "error");
};

export const AlertWarning = message => {
    return snackbarService.showSnackbar(message, "warning");
};

export const AlertInfo = message => {
    return snackbarService.showSnackbar(message, "info");
};
