import * as actions from "../Constants/actionTypes";
import { AlertError, AlertSuccess } from "./../../Utils/alertUtils";

const initState = {
    isLoading: false,
    profile: {},    
};

const userReducer = (state = initState, action) => {
    switch (action.type) {
        // change password
        case actions.AT_CHANGE_PASSWORD_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case actions.AT_CHANGE_PASSWORD_SUCCESS: {
            const { message } = action.payload;
            AlertSuccess(message);            
            return {
                ...state,
                isLoading: false
            };
        }
        case actions.AT_CHANGE_PASSWORD_FAILURE: {
            const { error } = action.payload;
            AlertError(error);
            return {
                ...state,
                isLoading: false
            };
        }
        // update profile
        case actions.AT_UPDATE_PROFILE_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case actions.AT_UPDATE_PROFILE_SUCCESS: {
            AlertSuccess(action.payload);            
            return {
                ...state,
                isLoading: false
            };
        }
        case actions.AT_UPDATE_PROFILE_FAILURE: {
            const { error } = action.payload;
            AlertError(error);
            return {
                ...state,
                isLoading: false
            };
        }
        // get profile
        case actions.AT_USER_GET_PROFILE_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case actions.AT_USER_GET_PROFILE_SUCCESS: {
            const { first_name, last_name, } = action.playload;                      
            return {
                ...state,
                isLoading: false,
                profile: {
                    full_name: first_name + ' ' + last_name,
                    ...action.playload,
                },
            };
        }
        case actions.AT_USER_GET_PROFILE_FAILURE:
            const { error } = action.payload;
            AlertError(error);
            return {
                ...state,                
                isLoading: false,
                profile: {},
            };
        case actions.AT_USER_SET_PROFILE_NULL:
            return {
                ...state,                
                isLoading: false,
                profile: {},
            }; 
        // upload avatar user
        case actions.AT_UPLOAD_AVATAR_USER_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case actions.AT_UPLOAD_AVATAR_USER_SUCCESS: {
            AlertSuccess(action.payload);            
            return {
                ...state,
                isLoading: false
            };
        }
        case actions.AT_UPLOAD_AVATAR_USER_FAILURE: {
            const { error } = action.payload;
            AlertError(error);
            return {
                ...state,
                isLoading: false
            };
        }           
        default:
            return state;
    }
};

export default userReducer;
